<template>
  <en-table-layout :tableData="pageData.data" :loading="loading">
    <template slot="toolbar">
      <el-form-item label="审核状态" v-if="shopInfo.shop_type === 2" class="col-auto">
        <el-radio-group size="small" v-model="params.status" @change="handlePageCurrentChange()">
          <el-radio-button :label="0">审核中</el-radio-button>
          <el-radio-button :label="1">已通过</el-radio-button>
          <el-radio-button :label="2">未通过</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="商品类型" class="col-auto">
        <el-radio-group size="small" v-model="params.goods_ptype" @change="handlePageCurrentChange()">
          <el-radio-button :label="1">实物商品</el-radio-button>
          <el-radio-button :label="2">虚拟商品</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="关键词" class="col-auto">
        <el-input
          size="medium"
          v-model="params.keyword"
          placeholder="请输入关键字搜索"
          clearable
        />
      </el-form-item>

      <div class="col-auto">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <!--        <el-button size="small" @click="revs" type="primary">重置</el-button>-->
      </div>
    </template>

    <template slot="table-columns">
      <el-table-column
        label="商品名称"
        prop="goods_name"
        show-overflow-tooltip
        min-width="400"
      >
      </el-table-column>

      <el-table-column label="商品类型" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.goods_ptype === 1">实物商品</span>
          <span v-if="scope.row.goods_ptype === 2">虚拟商品</span>
        </template>
      </el-table-column>

      <el-table-column label="申请时间" width="160">
        <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>

      <el-table-column
        label="申请变更内容"
        prop="change_info"
        align="left">
      </el-table-column>

      <el-table-column
        v-if="params.status === 2"
        label="不通过原因"
        prop="message"
        show-overflow-tooltip
      >
      </el-table-column>
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="pageData.page_no"
      :page-size="pageData.page_size"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
      :total="pageData.data_total">
    </el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_goodsChange from '@/api/goods'
import {handleDownload} from '@/utils'
import {Foundation} from '@/../ui-utils'
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: {EnTableLayout},
  data() {

    return {
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        log_type: 1,//商品日志
        status: 0,
        goods_ptype: 1,
        page_no: 1,
        page_size: 20,
        keyword: ''

      },
      /** 列表数据 */
      /** 列表分页数据 */
      pageData: {
        data: [],
        page_size: 20,
        page_no: 1
      },

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      total: 0

    }
  },
  activated() {
    this.GET_dataList()
  },
  mounted() {
    this.GET_dataList()
  },
  methods: {
    GET_dataList() {
      API_goodsChange.getChangeList(this.params).then(res => {
        this.pageData = res;
        this.pageData.data_total = parseInt(res.data_total);
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size
      this.GET_dataList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page
      this.GET_dataList();
    },

    /** 搜索事件触发 */
    search() {
      this.GET_dataList();
    },
    revs() {
      this.params = {
        status: this.params.status,
        goods_ptype: '',
        page_no: 1,
        page_size: 20,
        keyword: ''
      };
      this.GET_dataList();
    }

  }
}
</script>

<style lang="scss">
</style>
